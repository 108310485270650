.custom-tooltip {
  margin: 10px;
//  padding: 10px;
//  background: #FFF5A6;
//  /*background: #d5a65b;*/
//}
//
//.custom-tooltip {
//  margin: 0;
  line-height: 24px;
  border: 1px solid #d9d9d9;
  //background-color: rgba(255, 255, 255, 0.8);
  background-color: rgba(255, 255, 255, 1);
  padding: 10px;

  .name {
    font-weight: bold;
    //border-top: 1px solid #f5f5f5;
    border-bottom: 1px solid #d9d9d9;
    margin: 0;
  }

  .description {
    margin: 0;
    //color: rgb(3, 62, 146);
    color: grey;
  }
  .value {
    margin: 0;
    color: rgb(3, 146, 5);
  }
  .value2 {
    margin: 0;
    color: rgb(3, 62, 146);
  }
}
