/** @format */

.CodeMirror-wrap {
  flex: 4 4;
  background: #fff;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 0.28571429rem;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0 0 0 0 transparent inset;
  transition: color 0.1s ease, border-color 0.1s ease;

  &.CodeMirror-focused {
    color: rgba(0, 0, 0, 0.95);
    border-color: #85b7d9;
    border-radius: 0.28571429rem;
    background: #fff;
    box-shadow: 0 0 0 0 rgba(34, 36, 38, 0.35) inset;
  }
}
