/** @format */

li {
  .survey-complete-icon svg {
    color: #00640094;
    margin: 0 5px;
        /*
      ~ .survey-complete-icon svg,
      > .survey-complete-icon svg
       {
        color: darkgreen;
      }
      */
    }

    .control {
      display:inline-block;
    }

  &:not(.disabled) .control * {
    // label,
    // a {
      color: #007bff;
      cursor: pointer;

      transition-duration: 0.1s;
      transition-timing-function: ease-in;

      &:hover {
        color: #0056b3;
        // text-decoration: underline;

      }
      // &:hover {
      
      // }
        // }
  }

  &.disabled .control * {
    label,
    a {
      color: #767676;
      cursor: default;
      // text-decoration: initial;
    }
  }
}
