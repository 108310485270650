/** @format */

$readonly-opacity: 0.45;

%make-readonly {
  background-color: #f2f2f2;
  opacity: $readonly-opacity;
}

%make-disabled {
  @extend %make-readonly;
  pointer-events: none;
}

// override Semantic's background color for read-only elements

// input[readonly], input:read-only {
//   background-color: #f2f2f2 !important;
// }

//
// iframeResizer doesn't work if height is set to 100%
//
body,
html {
  //height: 100%;
  height: initial !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: inherit; // reset semantic's settigns
}

.ui.form {
  input:not([type]),
  input[type='date'],
  input[type='datetime-local'],
  input[type='email'],
  input[type='file'],
  input[type='number'],
  input[type='password'],
  input[type='search'],
  input[type='tel'],
  input[type='text'],
  input[type='time'],
  input[type='url'],
  textarea {
    &:read-only {
      @extend %make-readonly;
      //background-color: #f2f2f2;
      //opacity: 0.45;
    }
    &:disabled {
      @extend %make-disabled;
      //background-color: #f2f2f2;
      //opacity: 0.45;
      //pointer-events: none;
    }
  }
}

// .ui.form .disabled.field,
// .ui.form .disabled.fields .field,
// .ui.form .field :disabled {
//   pointer-events: none;
//   background-color: #f2f2f2;
//   opacity: 0.45;
// }
// // .ui.form .disabled.field,
// // .ui.form .disabled.fields .field,
// .ui.form .field :read-only {
//   pointer-events: none;
//   background-color: #f2f2f2;
//   opacity: 0.45;
// }

$widget-line-height: 2em;
$inline-label-width: 200px;

.custom-inline-widget {
  display: flex;
  line-height: $widget-line-height;
  &.custom-email-widget,
  &.custom-number-widget,
  &.custom-text-widget {
    align-items: center; // vertical align to center for one-line elements
  }
  &.custom-textarea-widget {
  }

  label.custom-inline-label {
    flex: 0 1 $inline-label-width; // set width for all labels
    padding: 0 2px;
    font-weight: bold;
  }
  input,
  textarea {
    flex: 4 4; // wide input elements
  }
  &.custom-number-widget {
    input {
      flex: 0 0 200px; // narrow input element for numbers
    }
  }
  &.custom-radio-widget input[type='radio'] + span {
    margin-left: 10px;
  }
  &.custom-select-widget > select {
    margin-left: 36px;
  }

  &.font-family-monospace {
    input, textarea {
      font-family: monospace !important;
    }
  }
  //
}

.custom-title-field, .custom-title-field * {
  font-weight: normal !important;
  margin-top: 30px !important;
}

%red-label {
  background-color: #fcdfe2 !important; // перекрыть !important semantic-ui
  //color: #db2828;
  color: rgb(237, 85, 101) !important; // перекрыть !important semantic-ui
  //border-color: #db2828;
  border-color: rgb(
    237,
    85,
    101
  ) !important; // перекрыть !important semantic-ui
  display: block;
  font-size: 1em;
  font-weight: normal;
  padding: 1em;
}

// сообщение об ошибке
/*& ~*/
.ui.red.label {
  @extend %red-label;
}
/* тот же элемент, что до этого, но с большей специфичностью */
/*& ~*/
.ui.basic.red.pointing.label {
  @extend %red-label;
  margin-left: $inline-label-width;
  &:before {
    left: inherit; // сместить указатель с середины влево
  }
}

// description below widget
.custom-inline-widget .sui-description {
  position: relative;
  //padding: 0 16px;
  opacity: 0.8;
  line-height: $widget-line-height;

  .custom-inline-widget .sui-description {
    margin-left: $inline-label-width;
  }
}

/*
  quick fix to disable "oneOf" dropdown which is not manageable otherwise
 */

form.form.rjsf .field [role='listbox'].dropdown {
  @extend %make-disabled;
}
