/** @format */

// ul > li > label:hover {
//   color: #767676;
// }

.choose-survey-page {
  main li {
    position: relative;
    left: -33px;

    &.disabled {
      color: darkgrey;
    }

    & .control input[type='checkbox'] {
      margin: 3px 10px;
    }

  }
}
