/** @format */

$menu-back-color: #001031;
$base-font-color: rgb(74, 74, 74);
$a-color: #007bff;

.App {
  /*text-align: center;*/
}

.App svg text {
  font-family: Montserrat, sans-serif !important;
  color: $base-font-color;
}

.App-logo {
  /*height: 40vmin;*/
  /*pointer-events: none;*/
}

/*
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
*/

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

a {
  color: $a-color;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  &:hover {
    color: #0056b3;
    text-decoration: underline;
  }
  transition: all 0.2s ease-in-out;
}

svg a text {
  fill: $a-color;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li {
  font-family: Montserrat, sans-serif !important;
}

footer,
header {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    padding: 0 40px;
    font-weight: normal;
  }
}

footer {
  li {
    display: block;
  }
}

/* // https://stackoverflow.com/questions/30352431/css-transition-not-working-with-underline
main a {
  display: inline-block;
  &::after {
    content: '';
    // width: 0px;
    width: 100%;
    height: 1px;
    display: block;
    background: transparent;
    transition: 300ms;
  }
  &:hover::after {
    // width: 100%;
    background: $a-color;
    transition: all 0.2s ease-in-out;
  }
}
 */

nav.top-menu {
  overflow: auto;
  background-color: $menu-back-color;
  color: white;

  font-family: 'Bebas Neue Book', sans-serif;
  font-weight: 300;
  text-align: center;
  margin: 0 auto;

  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  //justify-content: space-between;
  justify-content: flex-start;
  align-items: center;
  min-height: 80px;

  a,
  a:visited,
  a:active,
  a:hover {
    font-size: 22px;
    color: white;
    text-decoration: none;
    display: inline-block;
    padding: 6px 20px;
    padding-top: 8px;
    border-bottom: solid 2px $menu-back-color;

    // transition-duration: 0.1s;
    // transition-timing-function: ease-in;

    transition: all 0.2s ease-in-out;
  }

  a:hover,
  //ui.button.tertiary:hover,
  //ui.button.tertiary:hover,
  .ui.button.tertiary:hover {
    border-bottom: solid 2px #00dbfd;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      padding: 0;
      margin: 0;
    }
  }

  .ui.button {
    &.tertiary {
      //-webkit-transition: color 0.1s ease !important;
      //transition: color 0.1s ease !important;
      border-radius: 0;
      margin: 0.28571429em 0.25em 0.28571429em 0 !important;
      padding: 0.5em !important;
      -webkit-box-shadow: none;
      box-shadow: none;
      //color: rgba(0,0,0,.6);
      color: white;
      background: 0 0;

      border-bottom: solid 2px $menu-back-color;

      &:not(:hover) {
        box-shadow: none !important;
        background: none !important;
      }

      &:hover {
        //-webkit-box-shadow: inset 0 -0.2em 0 #666;
        //box-shadow: inset 0 -0.2em 0 #666;
        //color: #333;
      }
    }
  }
}

hr {
  border-top-color: $menu-back-color;
}
