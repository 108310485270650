/** @format */

body {
  margin: 0;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  padding: 0;
  font-weight: 400;
  font-size: 14px;
  color: #212529;
}

@font-face {
  font-family: 'Bebas Neue Book';
  src: url('../fonts/Bebas-Neue-Book.ttf.woff') format('woff'),
    url('../fonts/Bebas-Neue-Book.ttf.svg#Bebas-Neue-Book') format('svg'),
    url('../fonts/Bebas-Neue-Book.ttf.eot'),
    url('../fonts/Bebas-Neue-Book.ttf.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.sv_main {
  /*background-image: url(https://bigdata.iis.ru/wp-content/themes/bigdata/static/img/bg.png);*/
  /*background-image: url(https://bigdata.iis.ru/static/img/bg.png);*/
  background-color: #001031;
  font-family: 'Montserrat', sans-serif !important;
}

/*.sv_main .sv_container {*/
/*  color: white;*/
/*}*/

.sv_main
  .sv_container
  .sv_body
  .sv_p_root
  .sv_q
  .sv_q_radiogroup.sv_q_radiogroup_inline:not(:last-child),
.sv_main
  .sv_container
  .sv_body
  .sv_p_root
  .sv_q
  .sv_q_checkbox.sv_q_checkbox_inline:not(:last-child),
.sv_main
  .sv_container
  .sv_body
  .sv_p_root
  .sv_q
  .sv_q_imgsel.sv_q_imagepicker_inline:not(:last-child) {
  display: block;
}

.sv_main .sv_body {
  border-top: none;
}

th.sv_matrix_cell_header {
  max-width: 250px;
}

/*
  override colors to be able to use same button styles as in Survey including theme
*/
#AspectsSummaryPage.sv_main.sv_default_css .sv_body {
  background-color: white;
  border-color: white;
}

/*#AspectsSummaryPage .content {
  padding: 0.5em 1em 1.5em 1em;
}*/

.disable-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

.sv_main.sv_default_css input[type='button']:disabled,
.sv_default_css button:disabled,
.sv_main.sv_default_css input[type='button']:disabled:hover,
.sv_default_css button:disabled:hover {
  background-color: rgba(60, 79, 109, 0.27);
  cursor: default;
}

.sv_main.sv_default_css input[type='button'],
.sv_default_css button {
  // color: white;
  // background-color: #001031;
}
.sv_main.sv_default_css input[type='button']:hover,
.sv_default_css button:hover {
  // background-color: #001031e6;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #212529;
}
