body {
  /*font-family: "Arial", sans-serif;*/
  //font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  //font-size: 14px;
}

#barChartContainer .bar {
  fill: #5f89ad;
}

#barChartContainer .bar:hover {
  fill: #89a8c2;
}

#barChartContainer .axis {
  font-size: 13px;
}

#barChartContainer .axis path,
#barChartContainer .axis line {
  /*fill: none;*/
  /*display: none;*/
  stroke: #c1c1c1;
}

#barChartContainer .label {
  font-size: 13px;
}
